import styled, { Interpolation } from "@emotion/styled";
import { colors } from "../../utilities";

const styles = {
  display: "inline-block",
  backgroundColor: colors.orange.base,
  color: colors.white,
  padding: "8px 32px",
  transition: "background-color 250ms, color 250ms",
  textTransform: "uppercase",
  letterSpacing: 1,
  border: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: colors.orange.dark,
    transition: "background-color 250ms, color 250ms",
  },
};

export const PrimaryWrapper = styled.div(styles);
export const PrimaryBtn = styled.button(styles);
