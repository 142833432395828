import styled, { Interpolation } from "@emotion/styled";
import { colors } from "../../utilities";

const styles = {
  display: "inline-block",
  backgroundColor: "transparent",
  color: colors.white,
  border: `2px solid ${colors.white}`,
  padding: "8px 32px",
  transition: "background-color 250ms, color 250ms",
  textTransform: "uppercase",
  letterSpacing: 1,
  cursor: "pointer",
  "&:hover": {
    backgroundColor: colors.white,
    color: colors.blue.base,
    transition: "background-color 250ms, color 250ms",
  },
};

export const OutlineWrapper = styled.div(styles);
export const OutlineBtn = styled.button(styles);
