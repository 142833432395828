interface IColor {
  light: string;
  base: string;
  dark: string;
}

interface IColors {
  orange: IColor;
  grey: IColor;
  blue: IColor;
  white: string;
  black: string;
}

export const colors: IColors = {
  orange: {
    light: "#FE9E65",
    base: "#E57B3D",
    dark: "#9E3F07",
  },
  blue: {
    base: "#1F386B",
    dark: "#0D2452",
    light: "#274787",
  },
  grey: {
    base: "#e6e7e9",
    light: "#ffffff",
    dark: "#696969",
  },
  white: "#ffffff",
  black: "#000000",
};

// const breakpoints = [576, 768, 992, 1200];
const breakpoints = [576, 768, 1050, 1400];
export const mediaQuery = breakpoints.map(bp => `@media (min-width: ${bp}px)`);
