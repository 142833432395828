import React from "react";
import { css } from "@emotion/core";
import styled from "@emotion/styled";
import { Wrapper } from "../wrapper";
import { colors, mediaQuery } from "../../utilities";
import { ReactComponent as Logo } from "../../media/images/icons/logo-minimal.svg";
import { DangerouslySetInnerHtml } from "../dangerously-set-inner-html";

interface INavigationProps {
  navigationBlocks: {
    title: string;
    content: string;
  }[];
}

const NavigationWrapper = styled.div({
  backgroundColor: colors.blue.base,
  paddingTop: 64,
  paddingBottom: 200,
  overflow: "hidden",
  position: "relative",
});

const FlexWrapper = styled.div({
  display: "flex",
  justifyContent: "flex-start",
  flexDirection: "column",
  [mediaQuery[1]]: {
    flexDirection: "row",
  },
});

const FlexItem = styled.div({
  marginRight: 96,
});

const MenuHeader = styled.div({
  textTransform: "uppercase",
  color: colors.orange.base,
  marginTop: 16,
  letterSpacing: 1,
  [mediaQuery[1]]: {
    marginTop: 0,
  },
});

const MenuItem = styled.div({
  color: colors.white,
});

export const Navigation: React.SFC<INavigationProps> = ({
  navigationBlocks,
}) => {
  return (
    <NavigationWrapper>
      <Logo
        css={{
          position: "absolute",
          left: "3%",
          top: 0,
          bottom: 0,
          height: "100%",
          "&& *": {
            fill: colors.blue.light,
          },
        }}
      />
      <Wrapper css={{ position: "relative" }}>
        <FlexWrapper>
          {navigationBlocks.map((nb, i) => (
            <FlexItem key={i}>
              <MenuHeader>{nb.title}</MenuHeader>
              <MenuItem>
                <DangerouslySetInnerHtml>{nb.content}</DangerouslySetInnerHtml>
              </MenuItem>
            </FlexItem>
          ))}
        </FlexWrapper>
      </Wrapper>
    </NavigationWrapper>
  );
};
